<template>
  <div class="my-box" v-loading="loading">
    <!-- 功能区域 -->
    <el-row>
      <el-card shadow="always">
        <el-form label-width="50px" class="topS">
          <el-row :gutter="10">
            <el-col :span="3">
              <el-form-item label="品牌" label-width="50px">
                <el-select
                  v-model="seekData.brandId"
                  @change="selectOne"
                  clearable
                  filterable
                  placeholder="请选择"
                >
                  <el-option
                    v-for="(item, index) in brandSelectData"
                    :key="index"
                    :label="item.brandName"
                    :value="item.id"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="5">
              <el-form-item label="门店">
                <el-select
                  v-model="seekData.hotelId"
                  @change="selectTwo"
                  clearable
                  filterable
                  placeholder="请选择"
                >
                  <el-option
                    v-for="(item, index) in hotelSelectData"
                    :key="index"
                    :label="item.name"
                    :value="item.id"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="3">
              <el-form-item label="建筑">
                <el-select
                  v-model="seekData.buildingId"
                  @change="selectThree"
                  clearable
                  filterable
                  placeholder="请选择"
                >
                  <el-option
                    v-for="(item, index) in buildingSelectData"
                    :key="index"
                    :label="item.buildingName"
                    :value="item.id"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="3">
              <el-form-item label="楼层">
                <el-select
                  v-model="seekData.floorId"
                  clearable
                  filterable
                  placeholder="请选择"
                >
                  <el-option
                    v-for="(item, index) in floorSelectData"
                    :key="index"
                    :label="item.floorName"
                    :value="item.id"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="3">
              <el-form-item label="房间号" label-width="60px">
                <el-input
                  placeholder="请输入内容"
                  v-model="seekData.roomNumber"
                  clearable
                  class="my-input"
                ></el-input>
              </el-form-item>
            </el-col>

            <el-col :span="3">
              <el-form-item label="故障类型" label-width="70px">
                <el-select
                  v-model="seekData.type"
                  clearable
                  filterable
                  placeholder="请选择"
                >
                  <el-option
                    v-for="item in deviceFaultSelectData"
                    :key="item.value"
                    :label="item.valuee"
                    :value="item.keyy"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="4" class="reset-button">
              <el-button type="primary" @click="handleSearch">查询</el-button>
              <el-button @click="reset">重置</el-button>
            </el-col>
          </el-row>
        </el-form>
        <!-- 按钮行 -->
      </el-card>
    </el-row>
    <!-- 内容区域 -->
    <el-row>
      <el-card shadow="always">
        <div class="my-header-table">
          <el-table
            ref="multipleTable"
            :data="tableData"
            tooltip-effect="dark"
            :height="height"
            style="width: 100%"
            @selection-change="handleSelectionChange"
          >
            <el-table-column type="selection" width="55"></el-table-column>
            <el-table-column prop="brandName" label="品牌"></el-table-column>
            <el-table-column prop="hotelName" label="门店"></el-table-column>
            <el-table-column
              prop="buildingName"
              label="建筑"
              sortable
            ></el-table-column>
            <el-table-column
              prop="floorName"
              label="楼层"
              sortable
            ></el-table-column>
            <el-table-column
              prop="roomNumber"
              label="房间号"
              sortable
            ></el-table-column>
            <el-table-column prop="typeName" label="故障类型">
              <template slot-scope="scope">
                <span v-if="scope.row.type == 'TXGZ'">通信故障</span>
                <span v-if="scope.row.type == 'SBGZ'">设备故障</span>
                <span v-if="scope.row.type == 'XLSH'">线路损坏</span>
                <span v-if="scope.row.type == 'ONLINE'">设备上线</span>
              </template>
            </el-table-column>
            <el-table-column prop="ct" label="时间"></el-table-column>

            <!-- <el-table-column prop="remark" label="描述" show-overflow-tooltip></el-table-column> -->
          </el-table>
          <el-pagination
            background
            layout="prev, pager, next, sizes, total, jumper"
            :page-sizes="[10, 20, 50, 100]"
            :page-size="seekData.pageSize"
            :current-page="seekData.pageNum"
            :total="total"
            @current-change="handleCurrentChange"
            @size-change="handleSizeChange"
          ></el-pagination>
        </div>
      </el-card>
    </el-row>
  </div>
</template>
<script>
import {
  getDeviceFaultAlarmList,
  editDeviceFault,
  getKeyySelect,
  getFloorSelect,
  getHotelSelect,
  getRoomTypelSelect,
  getBuildingSelect,
  getRights,
  getBrandSelect,
  getHotelSeek,
} from "@/api";
// import { regionData, CodeToText } from "element-china-area-data";
export default {
  // name:"gzjk",
  data() {
    return {
      height: null,
      multipleSelection: [],
      loading: false,
      // 建筑列表数据
      tableData: null,
      // 查询的数据
      total: null,
      seekData: {
        brandId: null,
        hotelId: null,
        buildingId: null,
        floorId: null,
        roomNumber: null,
        repaired: null,
        type: null,
        pageSize: 10,
        pageNum: 1,
      },

      // 下拉框的数据
      brandSelectData: null,
      hotelSelectData: null,
      buildingSelectData: null,
      floorSelectData: null,
      roomTypeSelectData: null,
      deviceFaultSelectData: null,
    };
  },
  computed: {},
  // 注册表格组件
  components: {},
  created() {
    // 调用初始化表格数据方法
    //this.initList(this.seekData);
    this.initialize();
  },
  beforeMount() {
    let h = document.documentElement.clientHeight || document.body.clientHeight;
    this.height = h - 240;
  },
  methods: {
    // 初始化表格数据
    initList(obj) {
      this.loading = true;
      getDeviceFaultAlarmList(obj)
        .then((res) => {
          this.loading = false;
          if (res.status === 200) {
            this.tableData = res.data.data.rows;
            this.total = res.data.data.total;
          }
        })
        .catch((err) => {
          this.loading = false;
        });
    },

    /* 初始化下拉框 */
    // 获取品牌下拉框
    initialize() {
      getBrandSelect().then((res) => {
        if (res.status === 200) {
          this.brandSelectData = res.data.data;
          // console.log(res)
        }
      });
      getKeyySelect().then((res) => {
        if (res.status === 200) {
          this.deviceFaultSelectData = res.data.data;
        }
      });
    },
    // 获取门店下拉框
    selectOne(id) {
      this.seekData.hotelId = null;
      this.seekData.buildingId = null;
      this.seekData.floorId = null;
      this.seekData.roomNumber = null;
      this.hotelSelectData = null;
      this.buildingSelectData = null;
      this.floorSelectData = null;
      getHotelSelect(id).then((res) => {
        // console.log(res)
        if (res.data.data) {
          this.hotelSelectData = res.data.data;
        } else {
          this.$message({
            message: "该品牌下没有门店数据",
            type: "warning",
          });
        }
      });
      // 获取房间类型下拉框
      // getRoomTypelSelect(id).then((res) => {
      //   console.log('------------------',res);
      //   if (res.status === 200) {
      //     this.roomTypeSelectData = res.data.data;
      //     // console.log(res)
      //   }
      // });
    },
    // 获取建筑下拉框
    selectTwo(id) {
      this.seekData.floorId = null;
      this.seekData.roomNumber = null;
      this.buildingSelectData = null;
      this.floorSelectData = null;
      getBuildingSelect(id).then((res) => {
        // console.log(res)
        this.seekData.buildingId = null;
        if (res.data.data.length > 0) {
          this.buildingSelectData = res.data.data;
          this.seekData.buildingId = res.data.data[0].id;
          this.selectThree(this.seekData.buildingId);
        } else {
          this.$message({
            message: "该门店下没有建筑数据",
            type: "warning",
          });
        }
      });
    },
    // 获取楼层下拉框
    selectThree(id) {
      // console.log(id)
      getFloorSelect(id).then((res) => {
        console.log(res);
        if (res.data.data.length > 0) {
          this.floorSelectData = res.data.data;
        } else {
          this.$message({
            message: "该建筑下没有楼层数据",
            type: "warning",
          });
        }
      });
    },

    disposeBtn() {
      if (this.multipleSelection.length != 0) {
        // 把要删除的用户ID以字符串拼接
        let number = "";
        for (let i = 0; i < this.multipleSelection.length; i++) {
          const element = this.multipleSelection[i];
          number += element.id + ",";
        }
        number = number.slice(0, number.length - 1); //去掉最后的逗号
        this.$confirm("是否改为处理状态, 是否继续?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            this.loading = true;
            editDeviceFault(number).then((res) => {
              this.loading = false;
              if (res.data.code == "000000") {
                this.$message.success(res.data.message);
                this.initList(this.seekData);
              } else {
                this.$message.error(res.data.message);
              }
            });
          })
          .catch(() => {
            this.loading = false;
            this.$message({
              type: "info",
              message: "已取消删除",
            });
          });
      } else {
        this.$message.warning("请先选择要处理的数据");
      }
    },

    handleSelectionChange(val) {
      this.multipleSelection = val;
      // console.log(val)
    },

    // 查询按钮
    handleSearch() {
      // console.log(this.seekData)
      this.seekData.pageNum = 1;
      if (this.seekData) {
        this.initList(this.seekData);
      } else {
        this.initList();
      }
    },
    // 重置按钮
    reset() {
      (this.seekData.brandId = null),
        (this.seekData.hotelId = null),
        (this.seekData.buildingId = null),
        (this.seekData.floorId = null),
        (this.seekData.roomNumber = null),
        (this.seekData.mainBoardId = null),
        (this.seekData.repaired = null),
        (this.seekData.type = null);
      this.seekData.pageNum = 1;
      (this.hotelSelectData = null),
        (this.buildingSelectData = null),
        (this.floorSelectData = null);
      this.initList(this.seekData);
    },
    //分页
    handleCurrentChange(cpage) {
      this.seekData.pageNum = cpage;
      this.initList(this.seekData);
    },
    handleSizeChange(psize) {
      this.seekData.pageSize = psize;
      this.seekData.pageNum = 1;
      this.initList(this.seekData);
    },
  },
};
</script>
<style lang="scss" scoped>
.my-box {
  .astrict {
    .el-select {
      width: 100%;
    }
  }
  .my-iemt {
    span {
      font-size: 14px;
    }
    white-space: nowrap;
  }
  .my-input {
    max-width: 200px;
  }
  .el-row {
    margin-bottom: 10px;
    &:last-child {
      margin-bottom: 0;
    }
  }
  .el-col {
    border-radius: 4px;
  }

  .grid-content {
    border-radius: 4px;
    height: 36px;
    white-space: nowrap;
    .el-input__inner {
      max-width: 190px;
    }
    span {
      font-size: 14px;
    }
  }
}
</style>

